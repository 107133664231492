//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "UserPenIcon"
}
