//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "GearIcon",
  props: {
    height: {
      type: Number,
      default: 10,
    },
    width: {
      type: Number,
      default: 10
    }
  }
}
