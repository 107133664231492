//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";

import config from '/app.config';
import ListCheckIcon from "@/components/icons/ListCheckIcon";

export default {
  name: "NavLinks",
  components: {ListCheckIcon},
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hideTrainings: config.variables && !config.variables.showTrainingsTab,
    }
  },
  computed: {
    links(){
      const firstTabs = [
        {
          name: this.$i18n.t('nav_links.courses'),
          link: {
            name: 'courses',
          },
          isActive: this.isActive('courses'),
          icon: this.$isMobile() ? 'fa-user-graduate' : 'fa-graduation-cap',
        },
        ...(!this.hideTrainings ?
          [{
            name: this.$i18n.t('nav_links.external_education'),
            link: {
              name: 'external_education',
            },
            isActive: this.isActive('external_education'),
            icon: this.$isMobile() ? 'fa-graduation-cap' : 'fa-user-tie',
          }] : [])
        ,
        {
          name: this.$i18n.t('nav_links.documents'),
          link: {
            name: 'documents',
          },
          isActive: this.isActive('documents'),
          icon: this.$isMobile() ? 'fa-file' : 'fa-list'
        },
      ]

      if (this.showDocumentsFirst) {
        firstTabs.reverse()
      }

      return [
        ...firstTabs,
        ...( this.hideTrainings ? 
          [] :
          [{
            name: this.$i18n.t('nav_links.trainings'),
            link: {
              name: 'trainings',
            },
            isActive: this.isActive('trainings'),
            icon: this.$isMobile() ? 'fa-list-check' : 'fa-book',
            target: '_blank',
          }]
        ),
      ];
    },
    ...mapState([
      'showDocumentsFirst'
    ])
  },
  methods: {
    isActive(linkName) {
      return this.$router.currentRoute.name === linkName
    },
    clearExtCourse(link) {
      console.log("clearExtCourse: ", link);
      if (link.link.name === 'courses')
        this.$store.commit('setCourse', {});
    }
  }
}
